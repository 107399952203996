import { AuthProviderProps, UserManager } from 'oidc-react';
import { Config } from './../config';

export function getOidcConfig(config: Config): AuthProviderProps {
  const props: AuthProviderProps = {
    onSignIn: (user) => {
      window.location.replace(typeof user?.state === 'object' && user.state ? user.state.toString() : window.location.pathname);
    },
    onBeforeSignIn: () => {
      return window.location.pathname + window.location.search;
    },
    authority: config.authorityUrl,
    clientId: 'SalgClientWithPkce',
    redirectUri: config.homeUrl,
    scope: 'openid profile email Salg',
    extraQueryParams: { acr_values: 'idp:' + config.idp },
    silentRedirectUri: `${config.homeUrl}/silent-renew.html`,
  };

  return {
    ...props,
    userManager: getUserManager(props),
  };
}

const getUserManager = (props: AuthProviderProps): UserManager => {
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams,
    metadata,
  } = props;
  return new UserManager({
    authority: authority ?? '',
    client_id: clientId ?? '',
    client_secret: clientSecret,
    redirect_uri: redirectUri ?? '',
    silent_redirect_uri: silentRedirectUri ?? redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri ?? redirectUri,
    response_type: responseType ?? 'code',
    scope: scope ?? 'openid',
    loadUserInfo: loadUserInfo ?? true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    extraQueryParams,
    metadata: metadata,
    redirectMethod: 'replace',
  });
};
