import state from './state';

export interface Config {
  version: string;
  homeUrl: string;
  apiUrl: string;
  authorityUrl: string;
  env: string;
  idp: string;
  bksUrl: string;
  featureToggleDeleteUser: boolean;
  featureToggleRemoveBounce: boolean;
  featureToggleTesting: boolean;
  defaultPollingInterval: number;
}

const getConfig = async (): Promise<Config> => {
  if (state && state.config) {
    return state.config;
  }
  const response = await fetch('/env');
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.message);
  }
  state.config = body;
  return body;
};

export default getConfig;
