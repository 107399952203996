import { FC } from 'react';
import { IconButton, Avatar, Toolbar, AppBar, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { useConfig } from '../components/Configuration';
import { useAuth } from 'oidc-react';
import { makeStyles } from '@mui/styles';
import { IdTokenClaims } from 'oidc-client-ts';
import gravatarUrl from 'gravatar-url';

const drawerWidth = 240;

interface TopHeaderProps {
  handleDrawerToggle(): void;
}

const useStyles = makeStyles((theme: any) => ({
  flex: {
    flex: 1,
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  env: {
    marginRight: 100,
  },
  version: {
    marginRight: 15,
  },
}));

export const TopHeader: FC<TopHeaderProps> = ({ handleDrawerToggle }) => {
  const classes = useStyles();
  const auth = useAuth();
  const config = useConfig();
  const initials = getInitials(auth.userData?.profile);
  const email = auth.userData?.profile?.email;

  const url = email ? gravatarUrl(email, { size: 200, default: '404' }) : undefined;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} className={classes.navIconHide} size="large">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.flex} noWrap>
          Salg
        </Typography>
        {config && <EnvironmentDisplay className={classes.env} environment={config.env} />}
        {config && (
          <Typography className={classes.version} variant="caption" color="inherit">
            v{config.version}
          </Typography>
        )}
        <Avatar src={url}>{initials}</Avatar>
      </Toolbar>
    </AppBar>
  );
};

function EnvironmentDisplay({ environment, className }) {
  const EnvTypograpy = ({ children }) => (
    <Typography variant="h3" color="inherit" className={className}>
      {children}
    </Typography>
  );

  if (environment === 'Dev') return <EnvTypograpy>DEV</EnvTypograpy>;
  if (environment === 'test') return <EnvTypograpy>TEST</EnvTypograpy>;
  if (environment === 'staging') return <EnvTypograpy>BETA</EnvTypograpy>;

  return null;
}

const getInitials = (profile?: IdTokenClaims) => {
  if (!profile || !profile.given_name?.length || !profile.family_name?.length) return '';
  return `${profile.given_name[0].toUpperCase()}${profile.family_name[0].toUpperCase()}`;
};
