import React from 'react';
import { Config } from './config';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import LoadingSpinner from './components/LoadingSpinner';
import { Typography } from '@mui/material';
import { AuthProviderProps } from 'oidc-react';

export function CustomApolloProvider({ config, oidcConfig, children }: { config: Config; oidcConfig: AuthProviderProps; children: React.ReactNode }) {
  const getToken = () => {
    const item = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.clientId}`);
    if (item === null) return null;

    return JSON.parse(item).access_token;
  };

  const httpLink = createHttpLink({ uri: `${config.apiUrl}/GraphQL` });

  const authMiddleware = new ApolloLink((operation, forward) => {
    var token = getToken();
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        'GraphQL-Preflight': 1,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError((error: any) => {
    let { networkError } = error;

    if (networkError && networkError.statusCode === 401) {
      //auth.signIn();
    }
  });

  const client = new ApolloClient({
    connectToDevTools: config.env === 'Development',
    link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getUsers: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  });

  if (!getToken())
    return (
      <>
        <LoadingSpinner />
        <Typography align="center">Logger inn</Typography>
      </>
    );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
